import Head from "next/head";
import Layout from "../components/Layout.js";
import HomePage from "../components/Home";
import styles from "../styles/Home.module.css";

export default function Home() {
  return (
    <Layout>
      <Head>
        <title>Merry Modz Advent Calendar</title>
        <meta name="description" content="Merry Modz Advent Calendar" />
        <link rel="icon" href="/favicon.ico" />
        <meta property="og:title" content="Merry Modz Advent Calendar" />
        <meta property="og:url" content="https://advent.merrymodz.io" />
        {/* <meta property="og:image" content="https://impacttheory.com/wp-content/uploads/2018/11/impact-theory-logo.png" /> */}
      </Head>

      <main className={styles.main}>
        <HomePage />
      </main>
    </Layout>
  );
}
